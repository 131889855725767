import {createRouter, createWebHistory} from "vue-router";
// import HomeView from "../views/HomeView.vue";
import StoreManagementView from "@/views/StoreManagement/StoreManagementView";
import UserManagementView from "@/views/UserManagement/UserManagementView";
import StoreUsersView from "@/views/StoreManagement/StoreUsersView";
import store from "@/store/index"
import permissionAPI from "../services/api";
// import Login from "../views/Login.vue";

const routes = [
    {path: '/:path(.*)', redirect: '/'},
    {
        path: "/logout",
        name: "logout",
        component: {
            // beforeRouteEnter() {
            //   return '/login';
            // }
        }
    },
    // {
    //   path: "/home",
    //   name: "home",
    //   component: HomeView,
    //   meta: { layout: "PageLayout", auth: false },
    // },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/Authen/Login.vue"),
        meta: {layout: "LoginLayout", auth: false},
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("../views/Authen/ForgotPassword.vue"),
        meta: {layout: "LoginLayout", auth: false},
    },
    {
        path: "/create-new-password",
        name: "create-new-password",
        component: () => import("../views/Authen/ForgotPassword.vue"),
        meta: {layout: "LoginLayout", auth: false},
    },
    {
        path: "/select-store",
        name: "select-store",
        component: () => import("../views/Authen/SelectStore.vue"),
        meta: {layout: "LoginLayout", auth: false},
    },
    {
        path: "/enter-email",
        name: "enter-email",
        component: () => import("../views/Authen/EnterEmail.vue"),
        meta: {layout: "LoginLayout", auth: false},
    },
    {
        path: "/new-password",
        name: "new-password",
        component: () => import("../views/Authen/CreateNewPassword.vue"),
        meta: {layout: "LoginLayout", auth: false},
    },
    // Scan & Check
    {
        path: "/",
        name: "scan",
        component: () => import("../views/ScanAndCheck.vue"),
        meta: {layout: "PageLayout", auth: true},
    },
    // Store Management
    {
        path: "/store",
        name: "store",
        component: StoreManagementView,
        meta: {layout: "PageLayout", auth: false},
    },
    {
        path: "/store/:storeId",
        name: "store-users",
        component: StoreUsersView,
        meta: {layout: "PageLayout", auth: false},
    },
    {
        path: "/store/add",
        name: "add-store",
        component: () => import("../views/StoreManagement/AddStore.vue"),
        meta: {layout: "PageLayout", auth: true},
    },
    {
        path: "/store/:storeId/edit",
        name: "edit-store",
        component: () => import("../views/StoreManagement/EditStore.vue"),
        meta: {layout: "PageLayout", auth: true},
    },
    // Users Management
    {
        path: "/user",
        name: "user",
        component: UserManagementView,
        meta: {layout: "PageLayout", auth: false},
    },
    {
        path: "/user/add",
        name: "add-user",
        component: () => import("../views/UserManagement/AddUser.vue"),
        meta: {layout: "PageLayout", auth: true},
    },
    {
        path: "/user/:userId/edit",
        name: "edit-user",
        component: () => import("../views/UserManagement/EditUser.vue"),
        meta: {layout: "PageLayout", auth: true},
    },
    // Bad Record
    {
        path: "/bad-record",
        name: "bad-record",
        component: () => import("../views/BadRecord/BadRecordManagementView.vue"),
        meta: {layout: "PageLayout", auth: true},
    },
    {
        path: "/bad-record/add",
        name: "add-bad-record",
        component: () => import("../views/BadRecord/AddBadRecord.vue"),
        meta: {layout: "PageLayout", auth: true},
    },
    {
        path: "/bad-record/:id/edit",
        name: "edit-bad-record",
        component: () => import("../views/BadRecord/EditBadRecord.vue"),
        meta: {layout: "PageLayout", auth: true},
    },
    {
        path: "/transaction",
        name: "transaction",
        component: () => import("../views/Transaction/TransactionManagementView"),
        meta: {layout: "PageLayout", auth: true},
    },
    {
        path: "/summary-report",
        name: "summary-report",
        component: () => import("../views/SummaryReport/SummaryReport2.vue"),
        meta: {layout: "PageLayout", auth: true},
    },
    {
        path: "/duplicate-report",
        name: "duplicate-report",
        component: () => import("../views/DuplicateReport/DuplicateReportView"),
        meta: {layout: "PageLayout", auth: true},
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkExactActiveClass: "active",
    routes,
});

const resetFilterFullPathList = ["/user", "/store", "/transaction", "/summary-report", "/duplicate-report", "/", "/bad-record"]

router.beforeEach(async (to, from) => {
    if (resetFilterFullPathList.includes(to.fullPath) && resetFilterFullPathList.includes(from.fullPath)) {
        await store.dispatch("filter/resetAllFilter")
    }
    const token = store.state.auth.token
    if (!token) {
        if (to.path !== "/login" && to.path !== "/forgot-password" && to.path !== "/create-new-password" && to.path !== "/new-password") {
            return '/login';
        }
    } else {
        if (to.path === "/login" || to.path === "/forgot-password" || to.path === "/create-new-password") {
            return '/';
        } else if (to.path === "/logout") {
            await store.dispatch('auth/logout')
            return '/login';
        }

        if (to.path === "/new-password") {
            return;
        }
        const storeId = store.state.auth.storeId
        const storeName = store.state.auth.storeName
        const userRole = store.state.auth.role
        if (userRole) {
            if (!storeId || !storeName) {
                if (to.path !== "/select-store") {
                    return "/select-store"
                }
            } else {
                if (!permissionAPI.hasPermission(userRole, to.name)) {
                    return "/";
                }
            }
        } else {
            return "/logout";
        }

    }
});

router.onError(error => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        window.location.reload()
    } else {
        console.error(error)
    }
})

export default router;
