import api from './api'

const getStoreInfo = async (storeId) => {
    const response = await api.getReqAuth(`store/${storeId}`, {}, null)
    return response
}

const searchStore = async (pageFilter) => {
    const response = await api.postReqAuth("store/search", {}, pageFilter)
    return response
}

const activeStore = async (storeId) => {
    try {
        const response = await api.postReqAuth(`store/${storeId}/activate`, {}, null)
        return response
    } catch (ex) {
        const errorResponseData = ex.response.data
        if (errorResponseData && errorResponseData.metaData) {
            throw ({ "error": `Unable activate store. ${errorResponseData.metaData}.` })
        } else {
            throw ({ "error": "Unable activate store. Unknown Error." })
        }
    }
}

const inactiveStore = async (storeId) => {
    try {
        const response = await api.postReqAuth(`store/${storeId}/deactivate`, {}, null)
        return response
    } catch (ex) {
        const errorResponseData = ex.response.data
        if (errorResponseData && errorResponseData.metaData) {
            throw ({ "error": `Unable deactivate store. ${errorResponseData.metaData}.` })
        } else {
            throw ({ "error": "Unable deactivate store. Unknown Error." })
        }
    }
}

const approveStore = async (storeId) => {
    try {
        const response = await api.postReqAuth(`store/${storeId}/approve`, {}, null)
        return response
    } catch (ex) {
        const errorResponseData = ex.response.data
        if (errorResponseData && errorResponseData.metaData) {
            throw ({ "error": `Unable approve store. ${errorResponseData.metaData}.` })
        } else {
            throw ({ "error": "Unable approve store. Unknown Error." })
        }
    }
}

const addStore = async (storeData) => {
    try {
        const response = await api.postReqAuth(`store`, {}, storeData)
        return response
    } catch (ex) {
        const errorResponseData = ex.response.data
        if (errorResponseData && errorResponseData.metaData) {
            throw ({ "error": `Unable add store. ${errorResponseData.metaData}.` })
        } else {
            throw ({ "error": "Unable add store. Unknown Error." })
        }
    }
}

const getStore = async (storeId) => {
    const response = await api.getReqAuth(`/store/${storeId}`, {}, null)
    return response
}


const updateStore = async (storeData, storeId) => {
    try {
        const response = await api.putReqAuth(`/store/${storeId}`, {}, storeData)
        return response
    } catch (ex) {
        const errorResponseData = ex.response.data
        if (errorResponseData && errorResponseData.metaData) {
            throw ({ "error": `Unable update store. ${errorResponseData.metaData}.` })
        } else {
            throw ({ "error": "Unable update store. Unknown Error." })
        }
    }
}


export default {
    searchStore, activeStore, approveStore, inactiveStore, addStore, getStore, updateStore, getStoreInfo
}
