<template>
  <div class="main-page-layout">
    <div class="page-header">
      <nav class="navbar navbar-expand-lg navbar-container">
        <div class="container-fluid navbar-content">
          <!-- LOGO -->
          <div class="brand-logo">
            <router-link class="navbar-brand top-logo" to="/">
              <img
                  class="enola-logo"
                  :src="require('@/assets/images/enola-logo2.png')"
              />
            </router-link>
          </div>

          <!-- menu hamburger -->
          <button
              ref="navbarToggler"
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label=""
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
              class="collapse navbar-collapse navbar-collapse-content"
              id="navbarSupportedContent"
          >
            <ul class="navbar-nav navbar-menu mb-2 mb-md-0">
              <li class="nav-item">
                <router-link @click="routerClick('/scan')" to="/scan" class="nav-link"
                             :class="{'router-link-active active':currentRoute==='/'}">
                  <span> Scan & Check </span>
                </router-link>
              </li>
              <li class="nav-item" v-show="hasPermission('bad-record')">
                <router-link @click="routerClick('/bad-record')" to="/bad-record" class="nav-link"
                             :class="{'router-link-active active':currentRoute==='/bad-record'}">
                  <span> Bad Record</span>
                </router-link>
              </li>
              <li
                  class="nav-item dropdown dropdown-report"
                  v-show="hasPermission('report')"
              >
                <a
                    class="nav-link dropdown-toggle navbarDropdown"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="true"
                >
                  Report
                </a>
                <ul class="dropdown-menu">
                  <div class="arrow"></div>
                  <li>
                    <router-link @click="routerClick('/transaction')" class="dropdown-item" to="/transaction">
                      Transactional Report
                    </router-link>
                  </li>
                  <li>
                    <router-link @click="routerClick('/summary-report')" class="dropdown-item" to="/summary-report">
                      Summary Report
                    </router-link>
                  </li>
                  <li>
                    <router-link @click="routerClick('/duplicate-report')" class="dropdown-item" to="/duplicate-report"
                    >Duplicated Waybill Report
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                  class="nav-item dropdown dropdown-setting"
                  v-show="hasPermission('setting')"
              >
                <a
                    class="nav-link dropdown-toggle navbarDropdown"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="true"
                >
                  Setting
                </a>
                <ul class="dropdown-menu">
                  <div class="arrow"></div>
                  <li>
                    <!-- <a
                      class="dropdown-item"
                      @click="this.$router.push('/store')"
                      >Store Management</a
                    > -->
                    <router-link @click="routerClick('/store')" class="dropdown-item" to="/store">
                      Store Management
                    </router-link>
                  </li>
                  <li>
                    <!-- <a class="dropdown-item" @click="this.$router.push('/user')"
                      >User Management</a
                    > -->
                    <router-link @click="routerClick('/user')" class="dropdown-item" to="/user">
                      User Management
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>

            <!-- User -->
            <!-- d-block d-md-none d-lg-block -->
            <div class="navbar-user">
              <button
                  class="d-none navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarUserDropdown"
                  aria-controls="navbarUserDropdown"
                  aria-expanded="false"
              ></button>

              <div
                  class="d-block d-lg-none collapse navbar-collapse"
                  id="navbarUserDropdown"
              >
                <ul class="navbar-nav">
                  <li class="nav-item dropdown">
                    <span
                        class="nav-link"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                      <div class="navbar-user-dropdown">
                        <div class="user-icon">
                          <img
                              class=""
                              :src="
                              require('@/assets/images/management/user-icon.png')
                            "
                          />
                        </div>

                        <div class="user-info">
                          <div class="user-name" :title="username">{{ username }}</div>
                          <div class="user-id" :title="`${storeId} - ${storeName}`">
                            {{ storeId }} - {{ storeName }}
                          </div>
                        </div>

                        <div class="arrow ms-2">
                          <i class="fa-solid fa-chevron-down"></i>
                        </div>
                      </div>
                    </span>
                    <ul class="dropdown-menu">
                      <div class="arrow"></div>
                      <li>
                        <span class="dropdown-item" @click="logout()">
                          Logout
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <!-- CONTENT -->
    <div class="page-content">
      <slot></slot>
    </div>

    <!-- FOOTER -->

    <div class="page-footer">
      <div class="content-footer">
        <div class="left-footer">{{ dateTimeString }}</div>
        <div class="right-footer">
          <span class="link-footer">Information and contact | Help</span>
          <span class="text-footer"> 2022 © ENOLA. All rights reserved.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Collapse} from 'bootstrap'
import {DateTime} from "luxon";
import {onMounted, ref, watch, computed} from "vue";
import {useStore} from "vuex";
import router from "@/router";
import permissionAPI from "../../services/api";

export default {
  name: "PageLayout",
  setup() {
    const {state, dispatch} = useStore();
    const username = ref(state.auth.username);
    const storeId = ref(state.auth.storeId);
    const storeName = ref(state.auth.storeName);
    // const userRole = ref(state.auth.role);
    const userRole = computed(() => state.auth.role);
    const dateTimeString = ref(DateTime.now().toFormat("dd LLL yyyy HH:mm:ss"));
    const navbarToggler = ref();

    function startTime() {
      dateTimeString.value = DateTime.now().toFormat("dd LLL yyyy HH:mm:ss");
      setTimeout(startTime, 1000);
    }

    const onWidthChange = () => {
      windowWidth.value = window.innerWidth;
    };

    const isMobileScreen = ref(false);
    const windowWidth = ref(window.innerWidth);
    const checkWidthLayout = () => {
      if (windowWidth.value < 576) {
        isMobileScreen.value = true;
      } else {
        isMobileScreen.value = false;
      }

      dispatch("setLayoutData", isMobileScreen.value);
    };

    const mobileNavBarObject = ref()
    onMounted(() => {
      startTime();

      window.addEventListener("resize", onWidthChange);
      checkWidthLayout();

      const mobileNavBar = document.getElementById('navbarSupportedContent')
      mobileNavBarObject.value = new Collapse(mobileNavBar, {
        toggle: false
      })
    });

    watch(
        () => windowWidth.value,
        () => checkWidthLayout()
    );

    const logout = () => {
      router.push({path: "/logout"});
    };

    const hasPermission = (routeName) => {
      return permissionAPI.hasPermission(userRole.value, routeName);
    };
    const currentRoute = computed(() => {
      return router.currentRoute.value.path
    })

    watch(currentRoute, () => {
      if (mobileNavBarObject.value && window.innerWidth <= 991) {
        mobileNavBarObject.value.hide();
      }
    })

    const routerClick = (path) => {
      let currentPath = currentRoute.value
      if (currentPath === "/") {
        currentPath = "/scan"
      }
      if (path === currentPath) {
        location.reload();
      }
    }

    return {
      dateTimeString,
      username,
      storeId,
      storeName,
      navbarToggler,
      logout,
      hasPermission,
      currentRoute,
      routerClick
    };
  },
};
</script>

<style lang="scss">
.dropdown-toggle::after{
  border-top: 0 !important;
  vertical-align: 0 !important;
}
.main-page-layout {
  height: 100vh;

  .page-header {
    .navbar-container {
      padding: 20px 0px;
      // padding: 0.75rem 0;
      height: 80px;
      background: linear-gradient(
              90deg,
              #ffcc00 0%,
              #ffcc00 29.59%,
              #ffde59 73.37%,
              #fff0b2 100%
      );
      box-shadow: 0 0.5rem 1 remrgba (0, 0, 0, 0.15),
      inset 0 -1px 0 rgba (0, 0, 0, 0.15);

      // position: sticky;
      // top: 0;
      // z-index: 1020;
    }

    .sticky-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }

    .navbar-content {
      align-items: baseline;
      padding: 0 70px;
      @media only screen and (max-width: 991px) {
        padding: 0;
      }

      .brand-logo {
        .enola-logo {
          height: 24px;
        }

        @media only screen and (max-width: 991px) {
          margin-left: 20px;
        }

        @media only screen and (min-width: 768px) {
          margin-right: 20px;
        }
      }

      .navbar-toggler {
        @media only screen and (max-width: 991px) {
          margin-right: 20px;
        }
      }

      .navbar-collapse-content {
        z-index: 999;
        justify-content: space-between;
        @media only screen and (max-width: 991px) {
          margin-top: 3px;
          padding: 20px;
          background: linear-gradient(
                  90deg,
                  #ffcc00 0%,
                  #ffcc00 29.59%,
                  #ffde59 73.37%,
                  #fff0b2 100%
          );
          border-radius: 0 0 4px 4px;
        }

        .dropdown-item {
          cursor: pointer;
        }

        .dropdown-menu.show {
          // background: #ffffff;
          box-shadow: 0px 3px 8px rgb(0 0 0 / 10%),
          0px 10px 24px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 10%);
          border-radius: 0px;
        }

        .dropdown-menu.show > .arrow,
        .dropdown-menu.show > .arrow:after {
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          border-color: transparent;
          border-style: solid;

          z-index: -30;
          right: 70%;
          border-width: 15px;
          top: -15px;
          border-top-width: 0;
          border-bottom-color: #999;
          border-bottom-color: white;
          @media only screen and (min-width: 992px) {
            left: 70%;
          }
        }

        #navbarUserDropdown {
          .dropdown-menu[data-bs-popper] {
            @media only screen and (max-width: 991px) {
              position: absolute;
            }
          }
        }

        .dropdown-report,
        .dropdown-setting {
          .dropdown-menu.show {
            padding: 20px 0;
          }

          .dropdown-menu[data-bs-popper] {
            top: 150%;
            left: initial;
            right: -20%;
            @media only screen and (max-width: 991px) {
              position: absolute;
              top: 40px;
              right: auto;
            }
          }

          .dropdown-item {
            padding: 10px 70px 10px 25px;
          }

          .nav-link.dropdown-toggle {
            &::after {
              display: contents;
              content: url("@/assets/images/arrow-down-black.png");
            }

            &.navbarDropdown:hover {
              color: #d40511;
              border-bottom: 2px solid #d40511;
              width: fit-content;

              &::after {
                display: contents;
                content: url("@/assets/images/arrow-down-red.png");
              }
            }

            &.navbarDropdown.show {
              color: #d40511;
              border-bottom: 2px solid #d40511;
              width: fit-content;

              &::after {
                display: contents;
                content: url("@/assets/images/arrow-up-red.png");
              }
            }
          }

          // nav-link
          // .nav-link.dropdown-toggle::after {
          //   display: contents;
          //   content: url("@/assets/images/arrow-down-black.png");
          // }

          // .nav-link.dropdown-toggle.navbarDropdown:hover {
          //   color: #d40511;
          //   border-bottom: 2px solid #d40511;
          //   width: fit-content;

          //   &::after {
          //     display: contents;
          //     content: url("@/assets/images/arrow-down-red.png");
          //   }
          // }

          // .nav-link.dropdown-toggle.navbarDropdown.show {
          //   color: #d40511;
          //   border-bottom: 2px solid #d40511;
          //   width: fit-content;

          //   &::after {
          //     display: contents;
          //     content: url("@/assets/images/arrow-up-red.png");
          //   }
          // }
        }

        .dropdown-report {
          .dropdown-menu[data-bs-popper] {
            right: 20%;

            @media only screen and (max-width: 991px) {
              position: absolute;
              top: 40px;
              right: auto;
            }
          }
        }

        .nav-item:not(:last-child) {
          padding-right: 36px;
        }

        .nav-link {
          min-height: 39px;
          font-weight: 700;
          font-size: 14px;
          color: #000000;
          width: fit-content;
        }

        .navbar-menu .nav-link:hover {
          color: #d40511;
          border-bottom: 2px solid #d40511;
          width: max-content;
        }

        .navbar-user {
          .navbar-user-dropdown {
            display: flex;
            flex-direction: row;
            // align-items: center;
            .user-info {
              max-width: 185px;
            }

            .user-icon {
              margin: 0 0.5rem 0 0;
            }

            .user-name {
              font-size: 16px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .user-id {
              color: #666666;
              font-weight: 400;
              font-size: 14px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .page-content {
    min-height: calc(100% - 190px);
    margin: 30px 80px;
    @media only screen and (max-width: 991px) {
      margin: 20px 20px 40px;
    }
  }

  /* Footer */
  .page-footer {
    min-height: 48px;
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin-top: 20px;

    .content-footer {
      display: flex;
      flex-wrap: inherit;
      justify-content: space-between;
      margin: 0 80px;
      @media only screen and (max-width: 991px) and (min-width: 768px) {
        margin: 0 30px;
      }

      @media only screen and (max-width: 767px) {
        display: block;
        margin: 0 20px;
        padding: 12px 0px;
      }

      .left-footer {
        color: #666666;
      }

      .right-footer {
        .link-footer {
          font-weight: 700;
          color: #d40511;
          margin-right: 12px;
          @media only screen and (max-width: 767px) {
            display: block;
          }
        }

        .text-footer {
          color: #666666;
        }
      }
    }
  }
}
</style>
