export default {
    namespaced: true,
    state: {
        storeFilter: {
            searchBarFilter: null,
            storeTypeFilter: null,
            sortField: null,
            sortDirection: null,
            page: null,
            sizePerPage: null
        },
        userFilter: {
            searchBarFilter: null,
            page: null,
            sizePerPage: null
        }
    },
    getters: {
        storeFilter: (state) => state.storeFilter
    },
    actions: {
        resetAllFilter({commit}) {
            commit('clearStoreFilter', null);
            commit('clearUserFilter', null);
        },
        setUserFilter({commit}, {searchBarFilter, page, sizePerPage}) {
            commit('setUserFilter', {searchBarFilter, page, sizePerPage});
        },
        setStoreFilter({commit}, {searchBarFilter, storeTypeFilter, sortField, sortDirection, page, sizePerPage}) {
            commit('setStoreFilter', {searchBarFilter, storeTypeFilter, sortField, sortDirection, page, sizePerPage});
        }
    },
    mutations: {
        clearStoreFilter(state) {
            state.storeFilter = {
                searchBarFilter: null,
                storeTypeFilter: null,
                sortField: null,
                sortDirection: null
            };
        },
        clearUserFilter(state) {
            state.userFilter = {
                searchBarFilter: null,
                page: null,
                sizePerPage: null
            };
        },
        setStoreFilter(state, data) {
            state.storeFilter = {
                searchBarFilter: data.searchBarFilter,
                storeTypeFilter: data.storeTypeFilter,
                sortField: data.sortField,
                sortDirection: data.sortDirection,
                page: data.page,
                sizePerPage: data.sizePerPage
            };
        },
        setUserFilter(state, data) {
            state.userFilter = {
                searchBarFilter: data.searchBarFilter,
                page: data.page,
                sizePerPage: data.sizePerPage
            };
        }
    },
};
