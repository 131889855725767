<template>
  <div class="layout-wrapper">
    <div class="row">
      <div class="d-none d-lg-block col-lg-6 left-block">
        <div class="info-container">
          <div class="info-content">
            <div class="text-wrapper">
              <div class="logo-wrapper">
                <img
                  class="logo-image"
                  :src="require('@/assets/images/ENOLA.png')"
                />
              </div>
              <div class="title-wrapper">
                <div class="title-page">
                  <div class="title-1">
                    Logictics Solution & Special Expertise
                  </div>
                  <div class="title-2">
                    We have a wide range of solutions
                    <br />
                    that can be tailored to your business' particular needs.
                  </div>
                </div>
              </div>
            </div>

            <div class="image-wrapper">
              <img
                class="login-image"
                :src="require('@/assets/images/login-layout-img.svg')"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 right-block">
        <div class="content-container">
          <div class="page-content">
            <Suspense>
              <slot></slot>
            </Suspense>
            <!-- <div class="footer">2022 © ENOLA. All rights reserved.</div> -->
          </div>
          <!-- footer -->
          <div class="footer">2022 © ENOLA. All rights reserved.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginLayout",
};
</script>

<style lang="scss">
.layout-wrapper {
  // height: 100vh;
  // min-height: -webkit-fill-available;
  // width: 100vw;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  .left-block {
    height: 100vh;
    background: linear-gradient(
      180deg,
      #ffcc00 0%,
      #ffcc00 29.97%,
      #ffe57f 78.91%,
      #fff0b2 99.88%
    );

    .info-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      @media (max-height: 720px) {
        justify-content: center;
      }
      @media (min-height: 1100px) {
        justify-content: center;
      }

      .info-content {
        .text-wrapper {
          text-align: center;
          .title-wrapper {
            margin-top: 80px;
            @media (max-height: 768px) {
              margin-top: 30px;
            }
            .title-page {
              // color: #000000;
              .title-1 {
                font-weight: 800;
                font-size: 30px;
                line-height: 40px;
              }
              .title-2 {
                margin-top: 16px;
                font-size: 16px;
              }
            }
          }
        }

        .image-wrapper {
          @media (max-height: 720px) {
            display: none;
          }
          // @media (min-height: 1100px) {
          //   display: none;
          // }
          // margin-top: 80px;
          // text-align: center;
          .login-image {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    // @media only screen and (max-width: 991px) {
    //   display: none;
    // }
  }

  .right-block {
    height: 100vh;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    .content-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .page-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center; // *
      }
      .footer {
        align-self: flex-end;
        padding-right: 20px;
      }
    }
  }
}
</style>
