<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import LoginLayout from "./LoginLayout.vue";
import PageLayout from "./PageLayout.vue";

export default {
  name: "AppLayout",
  components: {
    // NavLayout,
    LoginLayout,
    PageLayout,
  },
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      const name = route.meta.layout;
      return name;
    });
    return {
      layout,
    };
  },
};
</script>
