/* eslint-disable no-unused-vars, max-len */
import store from '@/store/index'
import router from '@/router/index'
import {useToast} from 'vue-toast-notification';
import _ from 'lodash';

import axios from 'axios';
import qs from 'qs';

const $toast = useToast()

const apiUrl = "/api"
// Interceptors for API call
const apiInterceptor = (response) => {
    // Auto extract data from response
    if (response.data) {
        return response.data;
    }
    return response;
};

// Setup Global intercepter
axios.interceptors.response.use(apiInterceptor, (error) => Promise.reject(error));

// Api Instance + intercepter
const apiInstance = axios.create({
    timeout: 600000,
});
apiInstance.interceptors.response.use(apiInterceptor, (error) => Promise.reject(error));


function getToken() {
    const token = store.state.auth.token
    if (token) {
        return token
    } else {
        router.replace({path: `/login`})
    }
}

const sendSessionExpiredWarning = _.debounce(() => {
    $toast.warning("Session Expired", {duration: 3000, position: "top-right"});
}, 500)

async function handleExpiredToken(ex) {
    if (ex.response.status === 401 || ex.response.status === 403) {
        sendSessionExpiredWarning()
        await store.dispatch('auth/clearAuthData')
        await router.replace({path: `/login`})
    } else {
        throw ex;
    }
}

function apiRequest(url, method, path, headers, data, params, asBlob) {
    const config = {
        method,
        headers,
        url: `${url}/${path}`,
        data,
        params,
    }
    if (asBlob) {
        config.responseType = "blob";
    }
    return apiInstance
        .request(config)
        .catch((e) => {
            throw e;
        });
}

function getReq(path, headers, params) {
    return apiRequest(apiUrl, 'get', path, headers, undefined, params);
}

function getReqAuth(path, headers, params) {
    headers.Authorization = getToken()
    if (store.state.auth.storeInternalId) {
        headers['X-STORE-ID'] = store.state.auth.storeInternalId;
    }
    return getReq(path, headers, params).catch(handleExpiredToken);
}

function delReq(path, headers, data, params) {
    return apiRequest(apiUrl, 'delete', path, headers, data, params);
}

function delReqAuth(path, headers, data, params) {
    headers.Authorization = getToken()
    if (store.state.auth.storeInternalId) {
        headers['X-STORE-ID'] = store.state.auth.storeInternalId;
    }
    return delReq(path, headers, data, params).catch(handleExpiredToken);
}

function postReq(path, headers, data, params, asBlob) {
    return apiRequest(apiUrl, 'post', path, headers, data, params, asBlob);
}

function postReqAuth(path, headers, data, params, asBlob) {
    headers.Authorization = getToken()
    if (store.state.auth.storeInternalId) {
        headers['X-STORE-ID'] = store.state.auth.storeInternalId;
    }
    return postReq(path, headers, data, params, asBlob).catch(handleExpiredToken);
}

function putReq(path, headers, data, params) {
    return apiRequest(apiUrl, 'put', path, headers, data, params);
}

function putReqAuth(path, headers, data, params) {
    headers.Authorization = getToken()
    if (store.state.auth.storeInternalId) {
        headers['X-STORE-ID'] = store.state.auth.storeInternalId;
    }
    return putReq(path, headers, data, params).catch(handleExpiredToken);
}

function extractErrorMessage(e) {
    if (typeof e === 'string') {
        return e;
    }
    if (e.response && e.response.data) {
        const d = e.response.data;
        return d.description || d.message || 'Unknown error';
    }
}


// check permission by route name and user role (routeName: [role])
const permission = {
    'select-store': ['BRANCH_STAFF', 'BUSINESS_ADMIN', 'RETAIL_MANAGER', 'IT_ADMIN'],
    'home': ['BRANCH_STAFF', 'BUSINESS_ADMIN', 'RETAIL_MANAGER', 'IT_ADMIN'],
    'scan': ['BRANCH_STAFF', 'BUSINESS_ADMIN', 'RETAIL_MANAGER', 'IT_ADMIN'], // scan&check
    'bad-record': ['BUSINESS_ADMIN', 'IT_ADMIN'], // bad-record
    'add-bad-record': ['BUSINESS_ADMIN', 'IT_ADMIN'],
    'edit-bad-record': ['BUSINESS_ADMIN', 'IT_ADMIN'],
    'report': ['BUSINESS_ADMIN', 'RETAIL_MANAGER', 'IT_ADMIN'], // * for hide menu at header
    'transaction': ['BUSINESS_ADMIN', 'RETAIL_MANAGER', 'IT_ADMIN'], // munu under report
    'summary-report': ['BUSINESS_ADMIN', 'RETAIL_MANAGER', 'IT_ADMIN'], // munu under report
    'duplicate-report': ['BUSINESS_ADMIN', 'RETAIL_MANAGER', 'IT_ADMIN'], // munu under report
    'setting': ['RETAIL_MANAGER', 'IT_ADMIN'], // * for hide menu at header
    'store': ['RETAIL_MANAGER', 'IT_ADMIN'], // store
    'store-users': ['RETAIL_MANAGER', 'IT_ADMIN'],
    'add-store': ['RETAIL_MANAGER', 'IT_ADMIN'],
    'edit-store': ['RETAIL_MANAGER', 'IT_ADMIN'],
    'user': ['RETAIL_MANAGER', 'IT_ADMIN'], // user
    'add-user': ['RETAIL_MANAGER', 'IT_ADMIN'],
    'edit-user': ['RETAIL_MANAGER', 'IT_ADMIN'],
};

function hasPermission(role, route) {
    if (permission[route]) {
        return permission[route].includes(role);
    }
    return false;
}

export default {
    getReq, getReqAuth, postReq, postReqAuth, putReq, putReqAuth, delReq, delReqAuth, extractErrorMessage, hasPermission
}

