<template>
  <div>
    <div class="header">
      <div class="header-label">
        <div class="title">User Management</div>
        <div class="subtitle">
          (<span>
            {{ totalStore.toLocaleString("en-US") }}
          </span>
          users)
        </div>
      </div>
      <div class="header-action">
        <search-bar @search="search" :value="searchText"></search-bar>
        <button
            @click="createUser"
            type="button"
            class="btn btn-primary-normal add-button"
        >
          Add New User
        </button>
      </div>
    </div>
    <div>
      <vue-element-loading :active="loading" spinner="bar-fade-scale"/>
      <user-table
          @userEdit="editUser"
          @changeUserStatus="changeUserStatus"
          :data="data"
      ></user-table>
      <div class="pagination">
        <v-pagination
            v-model="page"
            :pages="totalPage"
            :range-size="1"
            active-color="#DCEDFF"
        >
        </v-pagination>
      </div>
      <div class="page-size">
        <page-size-selector v-model="sizePerPage"></page-size-selector>
      </div>
    </div>
  </div>
</template>
<style scoped>
.Control {
  width: 25px;
  height: 25px;
}

.Page {
  font-size: 20px;
}

.header {
  display: flex;
  margin-bottom: 60px;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .header {
    flex-direction: column;
    gap: 15px;
  }
}

.title {
  font-weight: 800;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
}

.subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.btn.btn-primary-normal.add-button {
  max-width: 190px;
  height: 54px;
}

@media (max-width: 992px) {
  .add-button {
    width: 220px;
  }
}

.header-label {
  display: flex;
  text-align: left;
  flex-direction: column;
  flex: 2;
}

.header-action {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  flex: 3;
}

@media (max-width: 992px) {
  .header-action {
    justify-content: center;
  }
}

.pagination {
  margin-top: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-size {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>
<script>
import {ref, onMounted, watch} from "vue";
import UserTable from "@/components/UserTable";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import SearchBar from "@/components/SearchBar";
import VueElementLoading from "vue-element-loading";
import router from "@/router";
import userAPI from "@/services/user";
import {useToast} from "vue-toast-notification";
import PageSizeSelector from "@/components/PageSizeSelector";
import {useStore} from "vuex";

export default {
  name: "UserManagementView",
  components: {
    SearchBar,
    UserTable,
    VPagination,
    VueElementLoading,
    PageSizeSelector
  },
  setup() {
    const {state, dispatch} = useStore()
    const cachedFilter = state.filter.userFilter
    const $toast = useToast();
    const searchText = ref(cachedFilter.searchBarFilter || null);
    const sizePerPage = ref(cachedFilter.sizePerPage || 10);
    const totalPage = ref(1);
    const page = ref(cachedFilter.page || 1);
    const totalStore = ref(0);
    const loading = ref(true);
    const createUser = () => {
      router.push({path: "/user/add"});
    };
    const changeUserStatus = async (userData) => {
      const userId = userData.userId;
      const changeToStatus = userData.changeToStatus;
      if (userId) {
        try {
          if (changeToStatus === "ACTIVE") {
            await userAPI.activeUser(userId);
            $toast.success("User activated", {
              duration: 3000,
              position: "top-right",
            });
          } else if (changeToStatus === "INACTIVE") {
            await userAPI.inactiveUser(userId);
            $toast.success("User inactivated", {
              duration: 3000,
              position: "top-right",
            });
          }
          await loadData();
        } catch (ex) {
          const error = ex.error || "Unknown error has occurred";
          $toast.error(error, {duration: 3000, position: "top-right"});
        }
      }
    };
    const editUser = (userId) => {
      router.push({path: `/user/${userId}/edit`});
    };
    const search = async (text) => {
      searchText.value = text;
      page.value = 1;
      await loadData();
    };
    const formatData = (it) => {
      const tableData = {
        id: it._id,
        username: it.username,
        role: it.role,
        status: it.status,
      };
      const storeCount = it.assignedStore.length;
      tableData.storeCount = storeCount;
      if (storeCount > 0) {
        const firstStore = it.assignedStore.shift();
        tableData.storeData = {
          id: firstStore._id,
          storeId: firstStore.storeId,
          storeName: firstStore.storeName,
        };
        tableData._children = it.assignedStore.map((store) => {
          return {
            storeData: {
              id: store._id,
              storeId: store.storeId,
              storeName: store.storeName,
            },
          };
        });
      } else {
        tableData.storeData = null;
        tableData._children = [];
      }
      return tableData;
    };
    const data = ref([]);
    const loadData = async (showLoading = true) => {
      if (showLoading) {
        loading.value = true;
      }
      const filter = {};
      const search = searchText.value;
      if (search) {
        filter.username = search;
      }
      const dataFromAPI = await userAPI.searchUser({
        filter: filter,
        page: page.value,
        sizePerPage: sizePerPage.value,
        sort: {
          field: "createdAt",
          direction: "descending",
        },
      });
      totalStore.value = dataFromAPI.total;
      data.value = dataFromAPI.data.map(formatData);
      totalPage.value = dataFromAPI.totalPage;
      await dispatch("filter/setUserFilter",{
        searchBarFilter: search,
        page: page.value,
        sizePerPage: sizePerPage.value
      })
      loading.value = false;
    };
    onMounted(async () => {
      await loadData();
    });
    watch(page, async () => {
      await loadData();
    });
    watch(sizePerPage, async () => {
      page.value = 1;
    })
    return {
      loading,
      page,
      sizePerPage,
      totalStore,
      data,
      editUser,
      changeUserStatus,
      createUser,
      search,
      searchText,
      totalPage,
    };
  },
};
</script>
