import authAPI from '@/services/auth'

export default {
    namespaced: true,
    state: {
        token: null,
        username: null,
        role: null,
        storeInternalId: null,
        storeId: null,
        storeName: null
    },
    getters: {
        token: (state) => state.token,
        username: (state) => state.username,
        storeId: (state) => state.storeId,
        storeName: (state) => state.storeName,
        userRole: (state) => state.role,
    },
    actions: {
        async login({commit}, {username, password, captchaToken}) {
            try {
                commit('clearAuthData', null);
                const token = await authAPI.login(username, password, captchaToken)
                commit('loginSuccess', token);
                const userInfo = await authAPI.getUserInfo()
                commit('updateUserInfo', userInfo)
                return {isSuccess: true}
            } catch (err) {
                commit('clearAuthData', err);
                const response = err.response
                let message
                if (response) {
                    const responseData = response.data
                    if (responseData && responseData.metaData) {
                        message = responseData.metaData
                    } else {
                        message = "Unknown error"
                    }
                } else {
                    message = "Unknown error"
                }
                return {isSuccess: false, error: message, status: response.status}
            }
        },
        async logout({commit, state}) {
            try {
                const token = state.token
                if (token) {
                    await authAPI.logout(token)
                }
                commit('clearAuthData', null);
                return true
            } catch (err) {
                commit('clearAuthData', err);
                return false
            }
        },
        async clearAuthData({commit}) {
            commit('clearAuthData', null);
        },
        async selectStore({commit}, {storeInternalId, storeId, storeName}) {
            commit('updateSelectStoreInfo', {storeInternalId, storeId, storeName});
        }
    }
    ,
    mutations: {
        loginSuccess(state, data) {
            state.token = data;
        },
        clearAuthData(state) {
            state.token = null;
            state.username = null;
            state.role = null;
            state.storeInternalId = null;
            state.storeId = null;
            state.storeName = null;
        },
        updateUserInfo(state, userInfo) {
            state.username = userInfo.username;
            state.role = userInfo.role;
        },
        updateSelectStoreInfo(state, storeInfo) {
            state.storeInternalId = storeInfo.storeInternalId;
            state.storeId = storeInfo.storeId;
            state.storeName = storeInfo.storeName;
        }
    },
};
