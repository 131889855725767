import api from './api'
import qs from 'qs'

const login = async (username, password, captchaToken) => {
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'}
    if (captchaToken != null) {
        headers["x-captcha-token"] = captchaToken
    }
    const response = await api.postReq("auth/login", headers,
        qs.stringify({
            username,
            password
        }))
    return response.token
}

const getCaptchaTokenSiteId = async () => {
    const response = await api.getReq("captcha/site-id", null, null)
    return response.siteId
}

const forgetPassword = async (username,mode) => {
    try {
        let path = "password/forgot-password"
        if(mode === "passwordExpire"){
            path += "?mode=passwordExpire"
        }
        const response = await api.postReq(path, null, {username: username})
        return response
    } catch (ex) {
        const errorResponseData = ex.response.data
        throw(errorResponseData);
    }
}

const createNewPassword = async (token, password) => {
    try {
        const response = await api.postReq("password/forgot-password/change-password", {Authorization: token}, {
            "token": token,
            "password": password
        })
        return response
    } catch (ex) {
        if (ex.response.status !== 401) {
            const errorResponseData = ex.response.data
            throw({status: errorResponseData.status, metaData: errorResponseData.metaData});
        } else {
            throw({status: ex.response.status});
        }
    }
}

const validateForgotPasswordToken = async (token) => {
    try {
        const response = await api.getReq("password/forgot-password/validate-token", {Authorization: token}, null)
        return response
    } catch (ex) {
        throw({status: ex.response.status});
    }
}

const logout = async (token) => {
    return api.postReq("auth/logout", {'Authorization': token}, null)
}

const getUserInfo = async () => {
    try {
        const response = await api.getReqAuth(`user`, {}, null)
        return response
    } catch (ex) {
        const errorResponseData = ex.response.data
        if (errorResponseData && errorResponseData.metaData) {
            throw({"error": `Unable get user info. ${errorResponseData.metaData}.`})
        } else {
            throw({"error": "Unable get user info. Unknown Error."})
        }
    }
}
export default {
    login, logout, getUserInfo, forgetPassword, createNewPassword, validateForgotPasswordToken, getCaptchaTokenSiteId
}
