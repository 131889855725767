import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store/index";
import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/scss/bootstrap.scss";
import "bootstrap"
// css
import "vue-select/dist/vue-select.css";
import "./assets/css/font.css";
import "./assets/css/style.scss";
import "vue-toast-notification/dist/theme-sugar.css";

import { library, dom } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(fas, fab, far)
dom.watch()

// css
import "./config/style.css";

// window.onload = () => {
//     document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale='+(1/window.devicePixelRatio));
// };

createApp(App)
    .use(store)
    .use(router)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#app");

