<template>
  <div class="d-flex justify-content-center align-items-center gap-3 w-100">
    <div class="label">
      Size per page :
    </div>
    <select class="form-select page-size-selector" aria-label="Size per page" v-model="pageSize" @change="onPageSizeChange"
            :disabled="isDisabled">
      <option selected value="10">10</option>
      <option value="15">15</option>
      <option value="20">20</option>
      <option value="25">25</option>
    </select>
  </div>
</template>
<style lang="scss" scoped>
.label {
  color: #666666
}

.page-size-selector {
  max-width: 100px;
}
</style>
<script>
import {ref} from "vue"

export default {
  props: ['modelValue', 'disabled'],
  emits: ['update:modelValue'],
  name: "PageSizeSelector",
  setup(props, {emit}) {
    const pageSize = ref(props.modelValue)
    const isDisabled = ref(props.disabled)
    const onPageSizeChange = () => {
      emit("update:modelValue", parseInt(pageSize.value));
    }

    return {
      onPageSizeChange,
      isDisabled,
      pageSize
    }
  }
}
</script>
