<template>
  <!-- <router-view /> -->
  <MainLayout>
    <router-view v-slot="{ Component,route }">
      <!-- <component :is="Component" /> -->
      <Transition name="slide-fade">
        <div :key="route.name">
          <component :is="Component"/>
        </div>
      </Transition>
    </router-view>
  </MainLayout>
</template>

<script>
import MainLayout from "./views/Layout/MainLayout.vue";

export default {
  components: {
    MainLayout,
  },
  setup() {
  },
};
</script>

<style>
/* @import "./config/style.css"; */
#app {
  font-family: "Delivery", "Sarabun", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #000000;
}

.slide-fade-enter-active {
  transition: all 0.7s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
