import {createStore} from "vuex";
import VuexPersistence from 'vuex-persist';
import auth from "./modules/auth";
import filter from "./modules/filter";


const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    key: 'ENOLA',
    // reducer: (state) => ({account: state.account}),
});

export default createStore({
    state: {
        isMobileScreen: false,
    },
    modules: {
        auth,
        filter
    },
    actions: {
        setLayoutData({commit}, data) {
            commit('setLayoutData', data);
        },
    },
    mutations: {
        setLayoutData(state, data) {
            state.isMobileScreen = data;
        },
    },
    plugins: [vuexLocal.plugin],
});
