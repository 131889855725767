import api from './api'

const getUserStore = async () => {
    const response = await api.getReqAuth("/user/store", {}, null)
    return response
}

const searchUserFromStore = async (pageFilter, storeInternalId) => {
    const response = await api.postReqAuth(`user/search?storeId=${storeInternalId}`, {}, pageFilter)
    return response
}

const getUserForAddToStore = async (storeId) => {
    const response = await api.getReqAuth(`user/get-user-for-add-to-store/${storeId}`, {})
    return response
}

const searchUser = async (pageFilter) => {
    const response = await api.postReqAuth("user/search", {}, pageFilter)
    return response
}

const activeUser = async (userId) => {
    try {
        const response = await api.postReqAuth(`user/${userId}/activate`, {}, null)
        return response
    } catch (ex) {
        const errorResponseData = ex.response.data
        if (errorResponseData && errorResponseData.metaData) {
            throw ({"error": `Unable activate user. ${errorResponseData.metaData}.`})
        } else {
            throw ({"error": "Unable activate user. Unknown Error."})
        }
    }
}

const inactiveUser = async (userId) => {
    try {
        const response = await api.postReqAuth(`user/${userId}/deactivate`, {}, null)
        return response
    } catch (ex) {
        const errorResponseData = ex.response.data
        if (errorResponseData && errorResponseData.metaData) {
            throw ({"error": `Unable deactivate user. ${errorResponseData.metaData}.`})
        } else {
            throw ({"error": "Unable deactivate user. Unknown Error."})
        }
    }
}

const addUser = async (userData) => {
    try {
        const response = await api.postReqAuth(`user`, {}, userData)
        return response
    } catch (ex) {
        const errorResponseData = ex.response.data
        if (errorResponseData && errorResponseData.metaData) {
            throw ({"error": `Unable add user. ${errorResponseData.metaData}.`})
        } else {
            throw ({"error": "Unable add user. Unknown Error."})
        }
    }
}

const getUser = async (userId) => {
    const response = await api.getReqAuth(`user/${userId}`, {}, null)
    return response
}

const updateUser = async (userData, userId) => {
    try {
        const response = await api.putReqAuth(`user/${userId}`, {}, userData)
        return response
    } catch (ex) {
        const errorResponseData = ex.response.data
        if (errorResponseData && errorResponseData.metaData) {
            throw ({"error": `Unable update user. ${errorResponseData.metaData}.`})
        } else {
            throw ({"error": "Unable update user. Unknown Error."})
        }
    }
}

const deleteUserFromStore = async (userId, storeId) => {
    try {
        const response = await api.delReqAuth(`user-store-associate/delete-by-user-and-store`, {}, {
            userId: userId,
            storeId: storeId
        })
        return response
    } catch (ex) {
        const errorResponseData = ex.response.data
        if (errorResponseData && errorResponseData.metaData) {
            throw ({"error": `Unable delete user from store. ${errorResponseData.metaData}.`})
        } else {
            throw ({"error": "Unable delete user from store. Unknown Error."})
        }
    }
}


const addExistingUser = async (data) => {
    try {
        const response = await api.postReqAuth(`user-store-associate`, {}, data)
        return response
    } catch (ex) {
        const errorResponseData = ex.response.data
        if (errorResponseData && errorResponseData.metaData) {
            throw ({"error": `Unable add existing user. ${errorResponseData.metaData}.`})
        } else {
            throw ({"error": "Unable add existing user. Unknown Error."})
        }
    }
}

export default {
    searchUser,
    activeUser,
    inactiveUser,
    getUserStore,
    getUserForAddToStore,
    searchUserFromStore,
    addUser,
    getUser,
    updateUser,
    deleteUserFromStore,
    addExistingUser
}
