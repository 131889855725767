<template>
  <div>
    <div class="header">
      <div class="header-label">
        <div class="title-with-back">
          <button @click="this.$router.back()" class="back-button">
            <i class="back-icon fa fa-arrow-left" aria-hidden="true"></i>
          </button>
          <div>
            <div class="title">{{ unescape(storeName) }}</div>
            <div class="subtitle">
              (<span>
                {{ totalUser }}
              </span>
              users)
            </div>
          </div>
        </div>
      </div>
      <div class="header-action">
        <search-bar @search="search"></search-bar>
        <button
            @click="addUser"
            type="button"
            class="add-button btn btn-danger"
        >
          Add New User
        </button>
      </div>
    </div>
    <div>
      <vue-element-loading :active="loading" spinner="bar-fade-scale"/>
      <user-table
          @userDeleteFromStore="deleteUserFromStore"
          @changeUserStatus="changeUserStatus"
          @userEdit="editUser"
          :hide-store="true"
          :data="data"
      ></user-table>
      <div class="pagination">
        <v-pagination
            v-model="page"
            :pages="totalPage"
            :range-size="1"
            active-color="#DCEDFF"
        >
        </v-pagination>
      </div>
      <div class="page-size">
        <page-size-selector v-model="sizePerPage"></page-size-selector>
      </div>
    </div>
    <div class="modal fade" id="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <vue-element-loading :active="modalLoading" spinner="bar-fade-scale"/>
          <div class="modal-header">
            <h5 class="modal-title">{{ modalTitle }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" v-html="modalMessage"></div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary-normal" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary-normal" :disabled="modalLoading"
                    @click="sendDeleteUserFromStoreRequest">{{
                modalAction
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.title-with-back {
  display: flex;
  gap: 5px;
}

.back-button {
  background-color: transparent;
  border: 0;
}

.back-icon {
  font-size: 30px;
}

.Control {
  width: 25px;
  height: 25px;
}

.Page {
  font-size: 20px;
}

.header {
  display: flex;
  margin-bottom: 60px;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .header {
    flex-direction: column;
    gap: 15px;
  }
}

.title {
  font-weight: 800;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
}

.subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.add-button {
  max-width: 190px;
  height: 54px;
  background-color: #d40511 !important;
}

@media (max-width: 992px) {
  .add-button {
    width: 220px;
  }
}

.header-label {
  display: flex;
  text-align: left;
  flex-direction: column;
  flex: 2;
}

.header-action {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  flex: 3;
}

@media (max-width: 992px) {
  .header-action {
    justify-content: center;
  }
}

.pagination {
  margin-top: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-size {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

</style>
<script>
import {Modal} from 'bootstrap'
import {ref, onMounted, watch} from "vue";
import {useRoute} from "vue-router";
import {useToast} from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import PageSizeSelector from "@/components/PageSizeSelector";
import UserTable from "@/components/UserTable";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import SearchBar from "@/components/SearchBar";
import VueElementLoading from "vue-element-loading";
import userAPI from "@/services/user";
import storeAPI from "@/services/store";
import router from "@/router/index";
import _ from "lodash";

export default {
  name: "StoreUsersView",
  components: {
    SearchBar,
    UserTable,
    VPagination,
    VueElementLoading,
    PageSizeSelector
  },
  setup() {
    const $toast = useToast();
    const route = useRoute();
    const routerPath = route.path.split("/");
    const storeInternalId = routerPath.slice(-1).pop();
    const selectedUserId = ref();
    const modalLoading = ref(false);
    const modalTitle = ref("");
    const modalMessage = ref("");
    const modalAction = ref("")
    const addUser = () => {
      router.push({path: `/user/add`, query: {storeId: storeId.value}});
    };
    const search = async (text) => {
      searchText.value = text
      page.value = 1;
      await loadData()
    };
    const changeUserStatus = async (userData) => {
      const userId = userData.userId;
      const changeToStatus = userData.changeToStatus;
      if (userId) {
        try {
          if (changeToStatus === "ACTIVE") {
            await userAPI.activeUser(userId);
            $toast.success("User activated", {
              duration: 3000,
              position: "top-right",
            });
          } else if (changeToStatus === "INACTIVE") {
            await userAPI.inactiveUser(userId);
            $toast.success("User inactivated", {
              duration: 3000,
              position: "top-right",
            });
          }
          await loadData();
        } catch (ex) {
          const error = ex.error || "Unknown error has occurred";
          $toast.error(error, {duration: 3000, position: "top-right"});
        }
      }
    };
    const sendDeleteUserFromStoreRequest = async () => {
      modalLoading.value = true
      try {
        const targetUserId = selectedUserId.value
        const targetStoreId = storeId.value
        await userAPI.deleteUserFromStore(targetUserId, targetStoreId)
      } catch (ex) {
        const error = ex.error || "Unknown error has occurred"
        $toast.error(error, {duration: 3000, position: "top-right"})
      }
      const modal = Modal.getOrCreateInstance(document.getElementById("modal"), {})
      try {
        await loadData(false)
      }finally {
        modal.hide()
        modalLoading.value = false
      }
    }
    const editUser = (userId) => {
      router.push({path: `/user/${userId}/edit`});
    };
    const formatData = (it) => {
      const tableData = {
        id: it._id,
        username: it.username,
        role: it.role,
        status: it.status,
      };
      const storeCount = it.assignedStore.length;
      tableData.storeCount = storeCount;
      if (storeCount > 0) {
        const firstStore = it.assignedStore.shift();
        tableData.storeData = {
          id: firstStore._id,
          storeId: firstStore.storeId,
          storeName: firstStore.storeName,
        };
        tableData._children = it.assignedStore.map((store) => {
          return {
            storeData: {
              id: store._id,
              storeId: store.storeId,
              storeName: store.storeName,
            },
          };
        });
      } else {
        tableData.storeData = null;
        tableData._children = [];
      }
      return tableData;
    };
    const data = ref([]);
    const sizePerPage = ref(10);
    const totalPage = ref(0);
    const totalUser = ref(0);
    const page = ref(1);
    const searchText = ref();
    const loading = ref(true);
    const storeId = ref();
    const storeDHLID = ref();
    const storeName = ref("Store");
    const deleteUserFromStore = (userData) => {
      selectedUserId.value = userData.userId
      const username = userData.username
      const modal = Modal.getOrCreateInstance(document.getElementById("modal"), {})
      modalTitle.value = "Delete user from store"
      modalMessage.value = `Do you want to remove user <span style="font-weight: bold;">${username}</span> from store '<span style="font-weight: bold;">${storeDHLID.value}: ${storeName.value}</span>'? The removed user will no longer has an access to the store on ENOLA.`
      modalAction.value = "Delete"
      modal.show()
    };
    const loadData = async (showLoading = true) => {
      if (showLoading) {
        loading.value = true;
      }
      const filter = {};
      const search = searchText.value;
      if (search) {
        filter.username = search;
      }
      const dataFromAPI = await userAPI.searchUserFromStore(
          {
            filter: filter,
            page: page.value,
            sizePerPage: sizePerPage.value,
            sort: {
              field: "createdAt",
              direction: "descending",
            },
          },
          storeInternalId
      );
      totalUser.value = dataFromAPI.total;
      data.value = dataFromAPI.data.map(formatData);
      totalPage.value = dataFromAPI.totalPage;
      loading.value = false;
    };
    onMounted(async () => {
      try {
        const storeData = await storeAPI.getStoreInfo(storeInternalId);
        storeId.value = storeData._id;
        storeName.value = storeData.storeName;
        storeDHLID.value = storeData.storeId;
        await loadData();
      } catch (ex) {
        router.replace({path: "/store"});
      }
    });
    watch(page, async () => {
      await loadData();
    });
    watch(sizePerPage, async () => {
      page.value = 1;
    })
    setTimeout(() => {
      loading.value = false;
    }, 1000);
    return {
      storeName,
      loading,
      page,
      sizePerPage,
      totalPage,
      totalUser,
      data,
      addUser,
      search,
      deleteUserFromStore,
      changeUserStatus,
      editUser,
      modalTitle,
      modalMessage,
      modalAction,
      modalLoading,
      sendDeleteUserFromStoreRequest,
      unescape:_.unescape
    };
  },
};
</script>
