<template>
  <div>
    <div class="header">
      <div class="header-label">
        <div class="title">Store Management</div>
        <div class="subtitle">
          (<span>
            {{ totalStore.toLocaleString('en-US') }}
          </span>
          stores)
        </div>
      </div>
      <div class="header-action">
        <search-bar @search="search" :value="searchText"></search-bar>
        <button
            @click="createStore"
            type="button"
            class="add-button btn btn-danger"
        >
          Add New Store
        </button>
      </div>
    </div>
    <div>
      <vue-element-loading :active="loading" spinner="bar-fade-scale"/>
      <div class="filter-bar">
        <div class="filter">
          <div class="filter-label">
            Filters:
          </div>
          <div class="filter-component">
            <vue-select
                class="filter-select"
                placeholder="Store Type"
                v-model="selectedStoreType"
                :options="[{ label: 'DHL Own', id: 'DHL_OWN' }, { label: 'Sale Partner', id: 'SALES_PARTNER' }]"
            />
          </div>
        </div>
        <div class="sort">
          <div class="filter-label">
            Sort:
          </div>
          <div class="filter-component">
            <vue-select
                class="filter-select no-delete"
                placeholder="Sort By"
                v-model="selectedSortBy"
                :options="[{ label: 'Creation Time', id: 'createdAt' },{ label: 'Store Name', id: 'storeName' }, { label: 'Store ID', id: 'storeId' }]"
            />
            <vue-select
                class="filter-select no-delete"
                placeholder="Direction"
                v-model="selectedSortDirection"
                :options="[{ label: 'Descending', id: 'descending' }, { label: 'Ascending', id: 'ascending' }]"
            />
          </div>
        </div>
      </div>
      <store-table
          @storeEdit="editStore"
          @storeAddUser="addUserToStore"
          @changeStoreStatus="changeStoreStatus"
          :data="data"
      ></store-table>
      <div class="pagination">
        <v-pagination
            v-model="page"
            :pages="totalPage"
            :range-size="1"
            active-color="#DCEDFF"
        >
        </v-pagination>
      </div>
      <div class="page-size">
        <page-size-selector v-model="sizePerPage"></page-size-selector>
      </div>
    </div>
    <div class="modal fade" id="change-status-modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <vue-element-loading :active="modalLoading" spinner="bar-fade-scale"/>
          <div class="modal-header">
            <h5 class="modal-title">{{ modalTitle }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" v-html="modalMessage">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary-normal" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary-normal" @click="changeStatus">{{ modalAction }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">

.filter-select {
  height: 48px;

  .vs__selected-options {
    flex-wrap: nowrap;
  }
}

.filter-select .vs__dropdown-toggle {
  height: 100%;
  width: 150px;
  border: 1px solid #8c8c8c;
  background: white;
  border-radius: 6px;
}

.filter-select.no-delete .vs__clear {
  display: none;
}

</style>
<style lang="scss" scoped>
.Control {
  width: 25px;
  height: 25px;
}

.Page {
  font-size: 20px;
}

.header {
  display: flex;
  margin-bottom: 60px;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .header {
    flex-direction: column;
    gap: 15px;
  }
}

@media (max-width: 992px) {
  .header {
    flex-direction: column;
    gap: 15px;
  }
}

.title {
  font-weight: 800;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
}

.subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.add-button {
  max-width: 190px;
  height: 54px;
  background-color: #d40511 !important;
}

@media (max-width: 992px) {
  .add-button {
    width: 220px;
  }
}

.header-label {
  display: flex;
  text-align: left;
  flex-direction: column;
  flex: 2;
}

.header-action {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  flex: 3;
}

@media (max-width: 992px) {
  .header-action {
    justify-content: center;
  }
}

.pagination {
  margin-top: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-size {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.filter-bar {
  min-height: 64px;
  background: #EBEBEB;
  padding: 8px;
  row-gap: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;

  .filter-label {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .filter-component {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    align-items: center;
  }

  .filter {
    display: flex;
    column-gap: 16px;
    align-items: center;
    flex-wrap: wrap;
  }

  .sort {
    display: flex;
    column-gap: 16px;
    align-items: center;
    flex-wrap: wrap;
  }


  @media (max-width: 544px) {
    .filter {
      flex-direction: column;
      align-items: start;
    }
    .sort {
      flex-direction: column;
      align-items: start;
    }
  }

}
</style>
<script>
import {Modal} from 'bootstrap'
import VueSelect from 'vue-select';
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import {ref, onMounted, watch} from "vue";
import PageSizeSelector from "@/components/PageSizeSelector";
import StoreTable from "@/components/StoreTable";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import SearchBar from "@/components/SearchBar";
import VueElementLoading from "vue-element-loading";
import storeAPI from "@/services/store";
import router from "@/router/index";
import {useStore} from "vuex";

export default {
  name: "StoreManagementView",
  components: {
    SearchBar,
    StoreTable,
    VPagination,
    VueSelect,
    VueElementLoading,
    PageSizeSelector,
  },
  setup() {
    const {state, dispatch} = useStore();
    const cachedFilter = state.filter.storeFilter
    const $toast = useToast();
    const searchText = ref(cachedFilter.searchBarFilter || null);
    const selectedStoreType = ref(cachedFilter.storeTypeFilter || null);
    const selectedSortBy = ref(cachedFilter.sortField || {label: 'Store ID', id: 'storeId'})
    const selectedSortDirection = ref(cachedFilter.sortDirection || {label: 'Ascending', id: 'ascending'})
    const data = ref([])
    const page = ref(cachedFilter.page || 1);
    const sizePerPage = ref(cachedFilter.sizePerPage || 10);
    const totalPage = ref(1);
    const changeStatusStoreId = ref(null);
    const totalStore = ref(0);
    const loading = ref(true);
    const modalLoading = ref(false);
    const modalTitle = ref("");
    const modalMessage = ref("");
    const modalAction = ref("")
    const createStore = () => {
      router.push({path: `/store/add`})
    };
    const addUserToStore = (storeId) => {
      router.push({path: `/user/add`, query: {storeId: storeId}});
    };
    const editStore = (storeId) => {
      router.push({path: `/store/${storeId}/edit`})
    };
    const changeStoreStatus = (data) => {
      const storeId = data.storeId;
      const storeName = data.storeName;
      const storeDHLID = data.storeDHLID;
      const storeType = data.storeType;
      let storeTypeLabel
      if (storeType === "SALES_PARTNER") {
        storeTypeLabel = "Sale Partner"
      } else if (storeType === "DHL_OWN") {
        storeTypeLabel = "DHL Own"
      }
      const changeToStatus = data.changeToStatus;
      const modal = Modal.getOrCreateInstance(document.getElementById("change-status-modal"), {})
      if (changeToStatus === "APPROVE") {
        changeStatusStoreId.value = storeId
        modalTitle.value = "Approve Store"
        modalMessage.value = `Do you want to approve store <span style="font-weight: bold;">${storeDHLID}: ${storeName}</span> as a <span style="font-weight: bold;">${storeTypeLabel}</span> store? This will make the store status changed to be 'Active' and user under the store can access to ENOLA.`
        modalAction.value = "Approve"
        modal.show()
      } else if (changeToStatus === "ACTIVE") {
        changeStatusStoreId.value = storeId
        modalTitle.value = "Active Store"
        modalMessage.value = `Do you want to active store <span style="font-weight: bold;">${storeDHLID}: ${storeName}</span> as a <span style="font-weight: bold;">${storeTypeLabel}</span> store? This will make the store status changed to be 'Active' and user under the store can access to ENOLA.`
        modalAction.value = "Active"
        modal.show()
      } else if (changeToStatus === "INACTIVE") {
        changeStatusStoreId.value = storeId
        modalTitle.value = "Inactive Store"
        modalMessage.value = `Do you want to deactivate store <span style="font-weight: bold;">${storeDHLID}: ${storeName}</span> with type of <span style="font-weight: bold;">${storeTypeLabel}</span> store? This will make the store status changed to be 'Inactive' and user under the store will no longer have access to ENOLA.`
        modalAction.value = "Inactive"
        modal.show()
      } else {
        changeStatusStoreId.value = null
        modalTitle.value = ""
        modalMessage.value = ""
        modalAction.value = ""
      }
    };
    const changeStatus = async () => {
      modalLoading.value = true
      const storeId = changeStatusStoreId.value
      try {
        if (modalAction.value === "Approve") {
          await storeAPI.approveStore(storeId)
          $toast.success("Store approved", {duration: 3000, position: "top-right"})
        } else if (modalAction.value === "Active") {
          await storeAPI.activeStore(storeId)
          $toast.success("Store activated", {duration: 3000, position: "top-right"})
        } else if (modalAction.value === "Inactive") {
          await storeAPI.inactiveStore(storeId)
          $toast.success("Store deactivated", {duration: 3000, position: "top-right"})
        }
      } catch (ex) {
        const error = ex.error || "Unknown error has occurred"
        $toast.error(error, {duration: 3000, position: "top-right"})
      }
      const modal = Modal.getOrCreateInstance(document.getElementById("change-status-modal"), {})
      try {
        await loadData(false)
      } finally {
        modal.hide()
        modalLoading.value = false
      }
    };
    const search = async (text) => {
      searchText.value = text
      page.value = 1;
      await loadData()
    };
    const formatData = (it) => {
      const tableData = {
        "id": it._id,
        "storeId": it.storeId,
        "storeName": it.storeName,
        "storeType": it.storeType,
        "status": it.status,
        "createdAt": it.createdAt,
        "updatedAt": it.updatedAt,
        "userCount": it.userCount
      }
      return tableData
    }
    watch(page, async () => {
      await loadData()
    });
    const loadData = async (showLoading = true) => {
      if (showLoading) {
        loading.value = true;
      }
      const filter = {}
      const search = searchText.value
      if (search) {
        filter.searchPhrase = search
      }

      const storeType = selectedStoreType.value
      if (storeType) {
        filter.storeType = storeType.id;
      }

      let sort = {
        "field": "createdAt", "direction": "descending"
      }
      const sortDirection = selectedSortDirection.value
      if (sortDirection) {
        sort.direction = sortDirection.id
      }

      const sortField = selectedSortBy.value
      if (sortField) {
        sort.field = sortField.id
      }

      const dataFromAPI = await storeAPI.searchStore({
        "filter": filter,
        "page": page.value,
        "sizePerPage": sizePerPage.value,
        "sort": sort
      })
      totalStore.value = dataFromAPI.total
      data.value = dataFromAPI.data.map(formatData)
      totalPage.value = dataFromAPI.totalPage

      await dispatch("filter/setStoreFilter", {
        searchBarFilter: search,
        storeTypeFilter: storeType,
        sortField: sortField,
        sortDirection: sortDirection,
        page: page.value,
        sizePerPage: sizePerPage.value
      });

      loading.value = false;
    }

    watch(sizePerPage, async () => {
      page.value = 1;
    })

    watch(selectedStoreType, async () => {
      await loadData()
    })

    watch(selectedSortBy, async (newSelectedSortBy, oldSelectedSortBy) => {
      if (!newSelectedSortBy) {
        selectedSortBy.value = oldSelectedSortBy
      } else {
        if (oldSelectedSortBy) {
          await loadData()
        }
      }
    })

    watch(selectedSortDirection, async (newSelectedSortDirection, oldSelectedSortDirection) => {
      if (!newSelectedSortDirection) {
        selectedSortDirection.value = oldSelectedSortDirection
      } else {
        if (oldSelectedSortDirection) {
          await loadData()
        }
      }
    })

    onMounted(async () => {
      const modalElem = document.getElementById('change-status-modal')
      modalElem.addEventListener('hidden.bs.modal', function () {
        changeStatusStoreId.value = null
      })
      await loadData()
    });
    return {
      loading,
      page,
      searchText,
      sizePerPage,
      totalStore,
      data,
      totalPage,
      addUserToStore,
      editStore,
      changeStoreStatus,
      createStore,
      search,
      changeStatus,
      selectedStoreType,
      modalLoading,
      modalTitle,
      modalMessage,
      modalAction,
      selectedSortBy,
      selectedSortDirection
    };
  },
};
</script>
