<template>
  <div style="width: 100%; min-height: 500px;">
    <div class="table" ref="table"></div>
  </div>
</template>

<script>
import {Popover} from 'bootstrap'
import 'tabulator-tables/dist/css/tabulator_bootstrap5.min.css'
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import {computed, ref, toRefs, onMounted, onUnmounted, watch} from 'vue';
import router from "@/router";
import {useStore} from "vuex";
import sanitizeHtml from 'sanitize-html';


export default {
  name: "StoreTable",
  props: ['data'],
  emits: ['storeEdit', 'storeAddUser', 'changeStoreStatus'],
  setup(props, {emit}) {
    const {state} = useStore();
    const table = ref(null);
    const tabulator = ref(null);
    const {data: tableData} = toRefs(props);
    const userRole = computed(() => state.auth.role);
    let tableBuiltPromiseResolve
    let tableBuiltPromise = new Promise(function (resolve) {
      tableBuiltPromiseResolve = resolve;
    });

    const actionIconFormatter = function (cell) { //plain text value
      return "<div class='action'>" +
          `<a tabindex="0" type="button" class="action-btn" data-bs-toggle="popover" data-store-status="${cell.getData().status}" data-store-id="${cell.getValue()}">` +
          "<i class='fa fa-ellipsis-h action-btn-icon'></i>" +
          "</a>" +
          "</div>";
    };
    watch(tableData, async (tableData) => {
      await tableBuiltPromise
      tabulator.value.replaceData(tableData)
    });
    const onAddUserStore = function (event) {
      const storeId = event.detail
      emit('storeAddUser', storeId);
    }
    const onEditStore = function (event) {
      const storeId = event.detail
      emit('storeEdit', storeId);
    }
    const onChangeStoreStatus = function (event) {
      const storeId = event.detail
      const storeData = tabulator.value.getRow(storeId).getData();
      const status = storeData.status
      const storeName = storeData.storeName
      const storeDHLID = storeData.storeId
      const storeType = storeData.storeType
      let changeToStatus
      if (status === "ACTIVE") {
        changeToStatus = "INACTIVE"
      } else if (status === "WAITING_FOR_APPROVAL") {
        changeToStatus = "APPROVE"
      } else if (status === "INACTIVE") {
        changeToStatus = "ACTIVE"
      } else {
        changeToStatus = "INACTIVE"
      }
      emit('changeStoreStatus', {
        storeId: storeId,
        changeToStatus: changeToStatus,
        storeName: storeName,
        storeDHLID: storeDHLID,
        storeType: storeType
      });
    }
    const onGoToStore = function (event) {
      const storeId = event.detail
      router.push({path: `/store/${storeId}`})
    }
    const createPopover = function (elem) {
      const storeId = elem.getAttribute("data-store-id")
      const storeStatus = elem.getAttribute("data-store-status")
      let buttonStatus
      if (storeStatus) {
        if (storeStatus.toLowerCase() === "active") {
          buttonStatus = "Inactive"
        } else if (storeStatus.toLowerCase() === "waiting_for_approval") {
          buttonStatus = "Approve Store"
        } else {
          buttonStatus = "Active"
        }
      } else {
        buttonStatus = "Active"
      }
      let content = "<div class=\"btn-group-vertical\">\n"
      if (userRole.value === "IT_ADMIN" || buttonStatus != "Approve Store") {
        content += `<button class='btn popover-btn' onclick='(function (){this.window.dispatchEvent(new CustomEvent("changeStoreStatus",{ detail:"${storeId}" }))})();'>${sanitizeHtml(buttonStatus)}</button>`
      }
      content += `<button class='btn popover-btn' onclick='(function (){this.window.dispatchEvent(new CustomEvent("addUserStore",{ detail:"${storeId}" }))})();'>Add New User</button>`
      if (storeStatus && storeStatus.toLowerCase() !== 'inactive') {
        content += `<button class='btn popover-btn' onclick='(function (){this.window.dispatchEvent(new CustomEvent("editStore",{ detail: "${storeId}" }))})();'>Edit</button>`
      }
      content += "</div>"
      const popover = new Popover(elem, {
        html: true,
        placement: "bottom",
        trigger: "focus",
        sanitize: false,
        content: content
      })
      return popover
    }
    const sanitizeFormatter = function (cell) {
      return sanitizeHtml(cell.getValue() || "")
    }
    onUnmounted(() => {
      window.removeEventListener('goToStore', onGoToStore);
      window.removeEventListener('addUserStore', onAddUserStore);
      window.removeEventListener('editStore', onEditStore);
      window.removeEventListener('changeStoreStatus', onChangeStoreStatus);
    });
    onMounted(() => {
      window.addEventListener('goToStore', onGoToStore);
      window.addEventListener('addUserStore', onAddUserStore);
      window.addEventListener('editStore', onEditStore);
      window.addEventListener('changeStoreStatus', onChangeStoreStatus);
      const tabulatorObject = new Tabulator(table.value, {
        height: "100%",
        layout: "fitColumns",
        data: tableData.value,
        rowFormatter: function (row) {
          if (row.getData().status == "INACTIVE") {
            row.getElement().style.color = "#D40511";
          }
        },
        // reactiveLayout: true,
        // responsiveLayout: true,
        // selectable: 1, // be able to select only 1 row at a time
        columns: [
          {
            title: "STORE ID",
            widthGrow: 1,
            widthShrink: 1,
            minWidth: 100,
            field: "storeId",
            sorter: "string",
            headerSort: false,
            headerHozAlign: "left",
            editor: false,
            formatter: sanitizeFormatter
          },
          {
            title: "STORE NAME",
            widthGrow: 2,
            widthShrink: 1,
            minWidth: 150,
            field: "storeName",
            sorter: "string",
            headerSort: false,
            editor: false,
            formatter: sanitizeFormatter
          },
          {
            title: "STORE TYPE",
            widthGrow: 1,
            widthShrink: 1,
            minWidth: 120,
            field: "storeType",
            sorter: "string",
            headerSort: false,
            editor: false,
            formatter: function (cell) {
              const type = cell.getValue()
              if (type === "DHL_OWN") {
                return "DHL Own"
              } else if (type === "SALES_PARTNER") {
                return "Sale Partner"
              } else {
                return sanitizeHtml(type)
              }
            }
          },
          {
            title: "NO. OF USERS",
            widthGrow: 1,
            widthShrink: 1,
            minWidth: 150,
            field: "userCount",
            headerSort: false,
            editor: false,
            formatter: function (cell) {
              const userCount = cell.getValue() || 0
              return `<a style="color: inherit;" onclick='(function (){this.window.dispatchEvent(new CustomEvent("goToStore",{ detail: "${cell.getData().id}" }))})();' class="user-link">${sanitizeHtml(userCount.toLocaleString('en-US'))} users</a>`
            }
          },
          {
            title: "STATUS",
            widthGrow: 2,
            widthShrink: 1,
            minWidth: 180,
            field: "status",
            sorter: "string",
            headerSort: false,
            editor: false,
            formatter: function (cell) {
              const type = cell.getValue()
              if (type === "ACTIVE") {
                return "Active"
              } else if (type === "INACTIVE") {
                return "Inactive"
              } else if (type === "WAITING_FOR_APPROVAL") {
                return "Waiting for approval"
              } else {
                return sanitizeHtml(type)
              }
            }
          },
          {
            title: "ACTION",
            widthGrow: 1,
            widthShrink: 1,
            minWidth: 100,
            field: "id",
            editor: false,
            headerSort: false,
            responsive: 0,
            formatter: actionIconFormatter,
          }]
      })
      tabulatorObject.on("renderComplete", function () {
        const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
        const popoverList = [...popoverTriggerList].map(popoverTriggerEl => createPopover(popoverTriggerEl))
        popoverList
      });

      tabulatorObject.on("tableBuilt", function () {
        tableBuiltPromiseResolve()
      })
      tabulatorObject.on("dataSorting", function (sorters) {
        sorters
      });
      tabulator.value = tabulatorObject
    });
    return {
      table,
      tabulator
    }
  }
}
</script>

<style>

.table {
  height: 500px;
}

.user-link {
  color: rgb(33, 37, 41);
}

.user-link:hover {
  color: #d40511;
}


.table.table > :not(caption) > * > * {
  padding: 0;
}

.tabulator .tabulator-header {
  border-top: 0 solid #dee2e6;
}

.tabulator-col-title {
  /*font-family: 'Delivery';*/
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}

.tabulator-row.tabulator-row-odd {
  background-color: white;
}

.tabulator-row.tabulator-row-even {
  background-color: white;
}

.action {
  font-size: 13px;
  display: flex;
  gap: 27px;
  margin-right: 12px;
  margin-left: 12px;
}

.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none !important;
  border: transparent;
  width: 30px;
  height: 30px;
  background-color: transparent;
}

.action-btn-icon {
  color: #D40511;
}

.popover-body {
  padding: 0 !important;
}

.popover-btn {
  height: 60px;
  min-width: 150px;
  text-align: left !important;
}
</style>
