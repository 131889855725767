<template>
  <div class="search-bar input-group flex-nowrap">
    <vue-element-loading :active="disabled" spinner="bar-fade-scale"/>
    <span class="input-group-text search-box-icon" id="addon-wrapping"><i class="fa-solid fa-magnifying-glass icon"></i></span>
    <input type="text" class="form-control" @keyup.enter="onSearch" @change="onSearch" placeholder="Search"
           aria-label="Search"
           v-model="inputText">
  </div>
</template>

<script>
import {ref,watch} from 'vue'
import _ from 'lodash'
import VueElementLoading from "vue-element-loading";

export default {
  props: ['value', 'disabled'],
  emits: ['search'],
  name: "SearchBar",
  components: {
    VueElementLoading
  },
  setup(props, {emit}) {
    const inputText = ref(props.value)
    const isDisabled = ref(props.disabled)
    const currentSearch = ref()
    const onSearch = () => {
      if (currentSearch.value !== inputText.value) {
        currentSearch.value = inputText.value
        emit("search", inputText.value);
      }
    }
    const onTextChange = _.debounce(onSearch, 500)
    watch(inputText, onTextChange);
    return {
      currentSearch,
      onSearch,
      onTextChange,
      inputText,
      isDisabled
    }
  }
}
</script>

<style scoped>
.search-bar {
  height: 48px;
  max-width: 300px;
}

.input-group-text.search-box-icon {
  border: 1px solid #8c8c8c;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;;
  border-right: 0;
}

.input-group-text {
  background-color: white;
  padding-right: 0;
}

.icon {
  color: #D40511
}

.form-control {
  border: 1px solid #8c8c8c;
  border-left: white;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}
</style>
