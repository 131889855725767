<template>
  <div style="width: 100%; min-height: 500px">
    <div class="table" ref="table"></div>
  </div>
</template>
<script>
import {Popover} from "bootstrap";
import router from "@/router/index";
import "tabulator-tables/dist/css/tabulator_bootstrap5.min.css";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import {ref, toRefs, onMounted, onUnmounted, watch} from "vue";
import sanitizeHtml from 'sanitize-html';
import {useStore} from "vuex";

export default {
  name: "UserTable",
  props: ["data", "hideStore"],
  emits: ["userEdit", "userDeleteFromStore", "changeUserStatus"],
  setup(props, {emit}) {
    const table = ref(null);
    const tabulator = ref(null);
    const {data: tableData, hideStore} = toRefs(props);
    const store = useStore();
    const loginRole = store.state.auth.role
    let tableBuiltPromiseResolve;
    let tableBuiltPromise = new Promise(function (resolve) {
      tableBuiltPromiseResolve = resolve;
    });
    const sanitizeFormatter = function (cell) {
      return sanitizeHtml(cell.getValue() || "")
    }
    const actionIconFormatter = function (cell) {
      if (cell.getValue()) {
        let result =
            "<div class='action'>" +
            `<a tabindex="0" type="button" class="action-btn" data-bs-toggle="popover" data-user-id="${cell.getValue()}" data-user-status=${
                sanitizeHtml(cell.getData().status)
            } data-user-role=${sanitizeHtml(cell.getData().role)}>` +
            "<i class='fa fa-ellipsis-h action-btn-icon'></i>" +
            "</a>";
        if (!hideStore.value && cell.getData().storeCount > 1) {
          let icon;
          if (cell.getRow().isTreeExpanded()) {
            icon = "fa-angle-down";
          } else {
            icon = "fa-angle-right";
          }
          result +=
              `<button type="button" onclick='(function (){this.window.dispatchEvent(new CustomEvent("toggleStore",{ detail: "${cell.getValue()}" }))})();' class="action-btn">` +
              `<i class='fa ${icon} action-btn-icon'></i>` +
              "</button>";
        }
        result += "</div>";
        return result;
      } else {
        return "";
      }
    };
    const listFormatter = function (cell) {
      const row = cell.getRow();
      const storeCount = cell.getData().storeCount;
      if (row.getTreeParent() || cell.getRow().isTreeExpanded()) {
        return `<div><a class="store-link" onclick='(function (){this.window.dispatchEvent(new CustomEvent("goToStore",{ detail: "${
            cell.getData().storeData.id
        }" }))})();'>${sanitizeHtml(cell.getData().storeData.storeName)}</a></div>`;
      } else {
        if (storeCount > 1) {
          return `<div><a class="store-link" onclick='(function (){this.window.dispatchEvent(new CustomEvent("toggleStore",{ detail: "${
              cell.getData().id
          }" }))})();'>${storeCount} Stores</a></div>`;
        } else if (storeCount === 1) {
          return `<div><a class="store-link" onclick='(function (){this.window.dispatchEvent(new CustomEvent("goToStore",{ detail: "${
              cell.getData().storeData.id
          }" }))})();'>${sanitizeHtml(cell.getData().storeData.storeName)}</a></div>`;
        } else {
          return "";
        }
      }
    };
    const onEditUser = function (event) {
      const userId = event.detail;
      emit("userEdit", userId);
    };
    const onDeleteUserFromStore = function (event) {
      const userId = event.detail;
      const username = tabulator.value.getRow(userId).getData().username
      emit("userDeleteFromStore", {userId: userId, username: username});
    };
    const cloneUser = function (event) {
      const userId = event.detail;
      // router.push({ path: `/user/add?clone=${userId}` });
      router.push({path: `/user/add`, query: {clone: userId}});
    };
    const onGoToStore = function (event) {
      const storeId = event.detail;
      router.push({path: `/store/${storeId}`});
    };
    const onToggleStore = function (event) {
      const userId = event.detail;
      tabulator.value.getRow(userId).treeToggle();
    };
    const onChangeUserStatus = function (event) {
      const userId = event.detail;
      const status = tabulator.value.getRow(userId).getData().status;
      let changeToStatus;
      if (status === "ACTIVE") {
        changeToStatus = "INACTIVE";
      }
      if (status === "INACTIVE") {
        changeToStatus = "ACTIVE";
      } else {
        changeToStatus = "INACTIVE";
      }
      emit("changeUserStatus", {
        userId: userId,
        changeToStatus: changeToStatus,
      });
    };
    const createPopover = function (elem) {
      const userId = elem.getAttribute("data-user-id");
      const status = elem.getAttribute("data-user-status");
      const role = elem.getAttribute("data-user-role");
      let buttonStatus;
      if (status) {
        if (status.toLowerCase() === "active") {
          buttonStatus = "Inactive";
        } else {
          buttonStatus = "Active";
        }
      } else {
        buttonStatus = "Active";
      }
      let content = `<div class="btn-group-vertical">`

      const isPassRetailManagerPermission = !(loginRole.toUpperCase() === "RETAIL_MANAGER" && !(role.toUpperCase() === "RETAIL_MANAGER" || role.toUpperCase() === "BRANCH_STAFF"))
      if (isPassRetailManagerPermission) {
        content += `<button class='btn popover-btn' onclick='(function (){this.window.dispatchEvent(new CustomEvent("changeUserStatus",{ detail: "${userId}" }))})();'>${sanitizeHtml(buttonStatus)}</button>` +
            `<button class='btn popover-btn' onclick='(function (){this.window.dispatchEvent(new CustomEvent("editUser",{ detail: "${userId}" }))})();'>Edit</button>`
      }

      content += `<button class='btn popover-btn' onclick='(function (){this.window.dispatchEvent(new CustomEvent("cloneUser",{ detail: "${userId}" }))})();'>Clone this user</button>`;
      if (hideStore.value) {
        if (isPassRetailManagerPermission) {
          content += `<button class='btn popover-btn' onclick='(function (){this.window.dispatchEvent(new CustomEvent("deleteUserFromStore",{ detail: "${userId}" }))})();'>Remove from this store</button>`;
        }
      }
      content += "</div>";
      const popover = new Popover(elem, {
        html: true,
        placement: "bottom",
        trigger: "focus",
        sanitize: false,
        content: content,
      });
      return popover;
    };

    watch(tableData, async (tableData) => {
      await tableBuiltPromise;
      tabulator.value.replaceData(tableData);
    });
    onUnmounted(() => {
      window.removeEventListener("toggleStore", onToggleStore);
      window.removeEventListener("goToStore", onGoToStore);
      window.removeEventListener("cloneUser", cloneUser);
      window.removeEventListener("changeUserStatus", onChangeUserStatus);
      window.removeEventListener("editUser", onEditUser);
      window.removeEventListener("deleteUserFromStore", onDeleteUserFromStore);
    });
    onMounted(() => {
      window.addEventListener("toggleStore", onToggleStore);
      window.addEventListener("goToStore", onGoToStore);
      window.addEventListener("cloneUser", cloneUser);
      window.addEventListener("changeUserStatus", onChangeUserStatus);
      window.addEventListener("editUser", onEditUser);
      window.addEventListener("deleteUserFromStore", onDeleteUserFromStore);
      let column = [
        {
          title: "USERNAME",
          widthGrow: 2,
          widthShrink: 1,
          minWidth: 150,
          field: "username",
          sorter: "string",
          headerSort: false,
          editor: false,
          formatter: sanitizeFormatter
        },
        {
          title: "ROLE",
          widthGrow: 1,
          widthShrink: 1,
          minWidth: 120,
          field: "role",
          sorter: "string",
          headerSort: false,
          editor: false,
          formatter: function (cell) {
            const type = cell.getValue();
            if (type === "IT_ADMIN") {
              return "IT Admin";
            } else if (type === "RETAIL_MANAGER") {
              return "Retail Manager";
            } else if (type === "BUSINESS_ADMIN") {
              return "Business Admin";
            } else if (type === "BRANCH_STAFF") {
              return "Branch Staff";
            } else {
              return type;
            }
          },
        },
        {
          title: "STORE",
          widthGrow: 2,
          widthShrink: 1,
          minWidth: 120,
          field: "storeData",
          sorter: "string",
          headerSort: false,
          editor: false,
          formatter: listFormatter,
        },
        {
          title: "STATUS",
          widthGrow: 1,
          widthShrink: 1,
          minWidth: 100,
          field: "status",
          sorter: "string",
          headerSort: false,
          editor: false,
          formatter: function (cell) {
            const type = cell.getValue();
            if (type === "ACTIVE") {
              return "Active";
            } else if (type === "INACTIVE") {
              return "Inactive";
            } else {
              return sanitizeHtml(type);
            }
          },
        },
        {
          title: "ACTION",
          widthGrow: 1,
          widthShrink: 1,
          minWidth: 100,
          field: "id",
          editor: false,
          headerSort: false,
          responsive: 0,
          formatter: actionIconFormatter,
        },
      ];
      if (hideStore.value) {
        column = column.filter((it) => it.title !== "STORE");
      }
      const tabulatorObject = new Tabulator(table.value, {
        height: "100%",
        dataTree: true,
        dataTreeExpandElement: "<div></div>",
        dataTreeCollapseElement: "<div></div>",
        layout: "fitColumns",
        data: tableData.value,
        rowFormatter: function (row) {
          let parent = row.getTreeParent()
          let data
          if (parent) {
            data = parent.getData()
          }else{
            data = row.getData()
          }
          if (data.status === "INACTIVE") {
            row.getElement().style.color = "#D40511";
          }
        },
        columns: column,
      });
      tabulatorObject.on("tableBuilt", function () {
        tableBuiltPromiseResolve();
      });
      tabulatorObject.on("renderComplete", function () {
        const popoverTriggerList = document.querySelectorAll(
            '[data-bs-toggle="popover"]'
        );
        const popoverList = [...popoverTriggerList].map((popoverTriggerEl) =>
            createPopover(popoverTriggerEl)
        );
        popoverList;
      });
      tabulator.value = tabulatorObject;
    });
    return {
      table,
      tabulator,
    };
  },
};
</script>

<style>
.table {
  height: 500px;
}

.store-link {
  color: inherit;
}

.store-link:hover {
  color: #d40511;
}

.table.table > :not(caption) > * > * {
  padding: 0;
}

.tabulator .tabulator-header {
  border-top: 0 solid #dee2e6;
}

.tabulator-tree-level-1 {
  border-width: 0 !important;
}

.tabulator-col-title {
  /*font-family: 'Delivery';*/
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}

.tabulator-row.tabulator-row-odd {
  background-color: white;
}

.tabulator-row.tabulator-row-even {
  background-color: white;
}

.tabulator-row {
  border-top: 1px solid #dee2e6;
  border-bottom: 0;
}

div.tabulator-table > div:first-child {
  border-top: 0;
}

div.tabulator-table > div:last-child {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6
}

.action {
  font-size: 13px;
  display: flex;
  gap: 27px;
  margin-right: 12px;
  margin-left: 12px;
}

.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none !important;
  border: transparent;
  width: 30px;
  height: 30px;
  background-color: transparent;
}

.action-btn-icon {
  color: #d40511;
}

.popover-body {
  padding: 0 !important;
}

.popover-btn {
  height: 60px;
  min-width: 150px;
  text-align: left !important;
}
</style>
